
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as authi5Z9GevJtV8EJH7Rj_f6mGskqcTy_455Yhdowy_dSPs8sMeta } from "/home/vsts/work/1/s/pages/auth.vue?macro=true";
import { default as documents7NGzydl5Xaue5_45LRmAY3Wv6X83lJ1nrmzlsmsRpCRIkMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/documents.vue?macro=true";
import { default as actionplansolk_453_4o6YhpZvAU6D2mO0d7HRcevXKCSQiVxNC1BboMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/actionplans.vue?macro=true";
import { default as additionsKv3K4nONdgpgrIXruBMxZgLtvQTHVxIw3TP2LhiZeV4Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/additions.vue?macro=true";
import { default as agreementJ1adQ5hh9_45_O9H6bltpmTphe7tMhjO6qGbw1p0HfzaIMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/agreement.vue?macro=true";
import { default as assortmentsVaebaBEI_wENyNe6l_isBIjzIlIyQR1_45vVkEWpVnelQMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/assortments.vue?macro=true";
import { default as detailstUdkVcGN0KEoyHLKmk5Vl8Pudaw9i_nu05jAd5Nke5QMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/details.vue?macro=true";
import { default as environmentcheck_2xiDCGJFnrM73z2rNKnypjkguwU4cZS_45zIwTVlU4BUMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentcheck.vue?macro=true";
import { default as environmentreportcGTisIC5wGHzMPtR_45a9GBj_L2jtXffye37uNk0ApKTQMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentreport.vue?macro=true";
import { default as finaldetailsyAeifyboZUJsbRFgLtwlgP_451g9T0uLN4i_roSEy05pAMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finaldetails.vue?macro=true";
import { default as finishuV1E_45OVcoweiOGH8E2G21ZPyOK7dli0QlDWsCdWBby8Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finish.vue?macro=true";
import { default as indexgdvkY9roRZs_45v_45Ob1w_adNPM_458_I7YeNx55JC66vJikMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/index.vue?macro=true";
import { default as loggingperiodIuT49cvFMyZ1INrG_QVEk7TrdJTP8EE_45l7VTlwDGmwMMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/loggingperiod.vue?macro=true";
import { default as planting6aY2gdDJ_XfTwxCBe9Nd0bwbdX5u_45k1gSLwAzGqlBigMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/planting.vue?macro=true";
import { default as pricesEv9AM1m5FOtD5eedKfuzxAPZu8lC8WPYHAEwb4SIEKYMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/prices.vue?macro=true";
import { default as signUKqWGyS6KHzIOBhRet97CC1i9YZizN21VTZsMNGWcY0Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/sign.vue?macro=true";
import { default as silvicultureZLxZBejB8PDy03xh3ldRh6_457zbY_WWnFhVm_45rwQc_45e8Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silviculture.vue?macro=true";
import { default as silvicultureSummary7MajfuSsj_8oQA8iaFOWKp44TpO32T_45upFIAaAbDcEcMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silvicultureSummary.vue?macro=true";
import { default as summary3HWTE1jbW6RZo09jqUGEX9jmy_45jB70ZUz_atTN6VODgMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/summary.vue?macro=true";
import { default as toppleplacesG8lSzN8BH_453s4WaqQoK6CUPrkmJUwj4zVvHnWZEuQxIMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/toppleplaces.vue?macro=true";
import { default as volumeAndEntrepreneurZ1TYs6dfb7nwOXVJ5XIMMSwVJ5wGMJsR11bYmlD76K0Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/volumeAndEntrepreneur.vue?macro=true";
import { default as edit9p1ji7Na0gUC_45q_f60v6TI3XC_45fjzmUpL7F3nCx0lCsMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit.vue?macro=true";
import { default as enviromentReportRjrIU4UAhwpcPdpOqf42KQzXqdBvovv2oUf8UnxIi24Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/enviromentReport.vue?macro=true";
import { default as fullsummaryF4pPUDwMR98Xl9UlxB1nkyCd4IOXABmv3nkZARtyH3EMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/fullsummary.vue?macro=true";
import { default as incidentsyAefQl_FBMO5YOSkXaSx8Xy6H_45MizF0avfSXIdoxIc4Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/incidents.vue?macro=true";
import { default as indexhuZgicVtgpystu7KF8aoncbiVS6cNHNEOTn6EoJBqcoMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/index.vue?macro=true";
import { default as indexqkUMdgVhcaFomQlyUdJDfj80bgYN_45fyPnZEXylHei7UMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/index.vue?macro=true";
import { default as editGXNQUx1aU1u5oW4C2jW_45vCRd9EzYr9V_zsGBVPF_2RMMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/firewood/[firewoodId]/edit.vue?macro=true";
import { default as indexOFnH4WvGEiKXu1dkb3aCs8JycCAN_OlR1FCmXk7yHjIMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/index.vue?macro=true";
import { default as firewood_45n_45DAVSBtXkseU_NUGm5sTf_45yej5_QI2Nz0tnl6pQv8Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/firewood.vue?macro=true";
import { default as indexzCZdq5e8QPGafH1f_usMD_YKyTUj_ltxUu2n5s2skyQMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/index.vue?macro=true";
import { default as wood_45for_45own_45usewqJrA7l4dxQz50Hb52M799C6Njbvmq2554GtS5oEpTcMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/wood-for-own-use.vue?macro=true";
import { default as new_45orderVhmC77bLvFEgnAkogKwdL9ffyIA2NHWQygODOWQLh3sMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order.vue?macro=true";
import { default as _91orderId_93MhXYZ5Y9j_457CEnw5steEyJgQdYrOstAgS_PEBz1GnYMMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/[orderId].vue?macro=true";
import { default as indexXI42WUGsnY4HcSjcVJ_T_EofkiVi70X1XoCtKl6J5ykMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/index.vue?macro=true";
import { default as projectY4sKtL4FEdOXgFDnZtDHZrXCIcAUAzc7eRYnYQtLaSkMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project.vue?macro=true";
import { default as timberTyeuW_45R72UZ2967GKj1IRYNZXqUz8uc16Aji6KzYQekMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/timber.vue?macro=true";
import { default as updatesignedISk_kma7sd_45U30xEVcd_m5bJJ1EmeY4ko8pX1vtcp_45MMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/updatesigned.vue?macro=true";
import { default as _91contractId_93uJ8yGswQ57dNOTnTgGjMzjuspa_6b_URLOpUsD6atScMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId].vue?macro=true";
import { default as indexihl4vWGuvtmc5y3hznw7Pjgm7ANR2IYUI3y0RwyVVW0Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/index.vue?macro=true";
import { default as newContractMOKBFl7g7KxveP14UnQ0hyZGcM4m0_wKtALTxUNCDt8Meta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/newContract.vue?macro=true";
import { default as _91propertyId_93nRyhPDCyoJP1wWeSIpS4v8r_45sDY97CpKo1LImU7ttQIMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId].vue?macro=true";
import { default as _91agreementId_93o6itncqc7lnCCe9PWQBE6crMVsRjkz6EBV66rEh5tNgMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/agreements/[agreementId].vue?macro=true";
import { default as ContractingPartiesTablel1rjQpbZkiyCovvIWG2z78tiJ5cVBXZmJBTZhMhF3PkMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/agreements/ContractingPartiesTable.vue?macro=true";
import { default as PermissionToForestManagementPlanDzfnwmIl4F9q8n99P2SiG4XwjFXj_45bBJrjl_45sdeVXzoMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/agreements/PermissionToForestManagementPlan.vue?macro=true";
import { default as PurposeTextIRufA93F8Yu_45CyP5B8fooM3OfH0605co_GOMiT8c3toMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/agreements/PurposeText.vue?macro=true";
import { default as indexPuM9jwlTX5nKm38HmXwCiVpvOVTF0t1PsAKkNmQwzEwMeta } from "/home/vsts/work/1/s/pages/customers/[customerId]/index.vue?macro=true";
import { default as _91customerId_93rBzVU9rciTlpIXwyEf4RYrFnDebhclIjHSX9vyg5qMYMeta } from "/home/vsts/work/1/s/pages/customers/[customerId].vue?macro=true";
import { default as indexLiSwOG_45EnsyrzSv9J7wVbtUE3hBMYBvu0fyT1w_45g_y8Meta } from "/home/vsts/work/1/s/pages/index.vue?macro=true";
import { default as loadingmTk_45H64kdxxDz_dm3bNyHfhKcmXj6yO0vPcdp6fazvcMeta } from "/home/vsts/work/1/s/pages/loading.vue?macro=true";
import { default as login2oDuAJw7jtPFfxdlIexRxoXb75ZKhNHj9lAAm_S3j0MMeta } from "/home/vsts/work/1/s/pages/login.vue?macro=true";
import { default as non_45invoiced_45ordersSc8XPHRmu_45m6EOUqCJanOVO4oeeX5KCez6e3hyOPXngMeta } from "/home/vsts/work/1/s/pages/non-invoiced-orders.vue?macro=true";
import { default as parked_45transportlZnGD9J6DYFLimYWAc1fCdLqRW8gv13MF5AViXNHbrsMeta } from "/home/vsts/work/1/s/pages/parked-transport.vue?macro=true";
import { default as projects_45ready_452_45closeIy36D_YIg1LWAKas2EDTZ3PIDIW671RcEBzAx_A7_45gkMeta } from "/home/vsts/work/1/s/pages/projects-ready-2-close.vue?macro=true";
import { default as indexQRuIGlxlAqyVNokR3yYWmNkNimCibc2xiFL9Etglo7QMeta } from "/home/vsts/work/1/s/pages/queue/index.vue?macro=true";
import { default as track_45damageGuzJ0pj5xLkmefCh2hdxWfv9sM7MEcCsJfJquKymcoAMeta } from "/home/vsts/work/1/s/pages/track-damage.vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    component: () => import("/home/vsts/work/1/s/pages/auth.vue")
  },
  {
    name: _91customerId_93rBzVU9rciTlpIXwyEf4RYrFnDebhclIjHSX9vyg5qMYMeta?.name,
    path: "/customers/:customerId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId].vue"),
    children: [
  {
    name: _91propertyId_93nRyhPDCyoJP1wWeSIpS4v8r_45sDY97CpKo1LImU7ttQIMeta?.name,
    path: ":propertyId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId].vue"),
    children: [
  {
    name: _91contractId_93uJ8yGswQ57dNOTnTgGjMzjuspa_6b_URLOpUsD6atScMeta?.name,
    path: ":contractId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId].vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-documents",
    path: "documents",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/documents.vue")
  },
  {
    name: edit9p1ji7Na0gUC_45q_f60v6TI3XC_45fjzmUpL7F3nCx0lCsMeta?.name,
    path: "edit",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-edit-actionplans",
    path: "actionplans",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/actionplans.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-additions",
    path: "additions",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/additions.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-agreement",
    path: "agreement",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/agreement.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-assortments",
    path: "assortments",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/assortments.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-details",
    path: "details",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/details.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-environmentcheck",
    path: "environmentcheck",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentcheck.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-environmentreport",
    path: "environmentreport",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/environmentreport.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-finaldetails",
    path: "finaldetails",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finaldetails.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-finish",
    path: "finish",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/finish.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-loggingperiod",
    path: "loggingperiod",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/loggingperiod.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-planting",
    path: "planting",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/planting.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-prices",
    path: "prices",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/prices.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-sign",
    path: "sign",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/sign.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-silviculture",
    path: "silviculture",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silviculture.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-silvicultureSummary",
    path: "silvicultureSummary",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/silvicultureSummary.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-summary",
    path: "summary",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/summary.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-toppleplaces",
    path: "toppleplaces",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/toppleplaces.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-edit-volumeAndEntrepreneur",
    path: "volumeAndEntrepreneur",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/edit/volumeAndEntrepreneur.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-enviromentReport",
    path: "enviromentReport",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/enviromentReport.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-fullsummary",
    path: "fullsummary",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/fullsummary.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-incidents",
    path: "incidents",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/incidents.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/index.vue")
  },
  {
    name: projectY4sKtL4FEdOXgFDnZtDHZrXCIcAUAzc7eRYnYQtLaSkMeta?.name,
    path: "project",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-project",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-firewood-firewoodId-edit",
    path: "overview/firewood/:firewoodId()/edit",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/firewood/[firewoodId]/edit.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview",
    path: "overview",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/index.vue")
  },
  {
    name: new_45orderVhmC77bLvFEgnAkogKwdL9ffyIA2NHWQygODOWQLh3sMeta?.name,
    path: "overview/new-order",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order.vue"),
    children: [
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order-firewood",
    path: "firewood",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/firewood.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/index.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-new-order-wood-for-own-use",
    path: "wood-for-own-use",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/new-order/wood-for-own-use.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-orders-orderId",
    path: "overview/orders/:orderId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/[orderId].vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-project-overview-orders",
    path: "overview/orders",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/project/overview/orders/index.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId-contractId-timber",
    path: "timber",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/timber.vue")
  },
  {
    name: "customers-customerId-propertyId-contractId-updatesigned",
    path: "updatesigned",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/[contractId]/updatesigned.vue")
  }
]
  },
  {
    name: "customers-customerId-propertyId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/index.vue")
  },
  {
    name: "customers-customerId-propertyId-newContract",
    path: "newContract",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/[propertyId]/newContract.vue")
  }
]
  },
  {
    name: "customers-customerId-agreements-agreementId",
    path: "agreements/:agreementId()",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/agreements/[agreementId].vue")
  },
  {
    name: "customers-customerId-agreements-ContractingPartiesTable",
    path: "agreements/ContractingPartiesTable",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/agreements/ContractingPartiesTable.vue")
  },
  {
    name: "customers-customerId-agreements-PermissionToForestManagementPlan",
    path: "agreements/PermissionToForestManagementPlan",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/agreements/PermissionToForestManagementPlan.vue")
  },
  {
    name: "customers-customerId-agreements-PurposeText",
    path: "agreements/PurposeText",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/agreements/PurposeText.vue")
  },
  {
    name: "customers-customerId",
    path: "",
    component: () => import("/home/vsts/work/1/s/pages/customers/[customerId]/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/vsts/work/1/s/pages/index.vue")
  },
  {
    name: "loading",
    path: "/loading",
    meta: loadingmTk_45H64kdxxDz_dm3bNyHfhKcmXj6yO0vPcdp6fazvcMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/loading.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login2oDuAJw7jtPFfxdlIexRxoXb75ZKhNHj9lAAm_S3j0MMeta || {},
    component: () => import("/home/vsts/work/1/s/pages/login.vue")
  },
  {
    name: "non-invoiced-orders",
    path: "/non-invoiced-orders",
    component: () => import("/home/vsts/work/1/s/pages/non-invoiced-orders.vue")
  },
  {
    name: "parked-transport",
    path: "/parked-transport",
    component: () => import("/home/vsts/work/1/s/pages/parked-transport.vue")
  },
  {
    name: "projects-ready-2-close",
    path: "/projects-ready-2-close",
    component: () => import("/home/vsts/work/1/s/pages/projects-ready-2-close.vue")
  },
  {
    name: "queue",
    path: "/queue",
    component: () => import("/home/vsts/work/1/s/pages/queue/index.vue")
  },
  {
    name: "track-damage",
    path: "/track-damage",
    component: () => import("/home/vsts/work/1/s/pages/track-damage.vue")
  }
]